/* eslint-disable camelcase */
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('pixel')
const { mapGetters: mapGettersAdsAccount } = createNamespacedHelpers('adsAccount')

export default {
  computed: {
    ...mapGettersAdsAccount(['listAccount']),

    showAdsAccount() {
      return !this.listAccount.content.length
    },
  },

  created() {
    this.createComponent()
  },

  methods: {
    createComponent() {
      const params = {
        page: 1,
        size: 10,
      }
      this.getPixels(params)
    },

    ...mapActions(['getPixels']),
  },
}
